
























import { Component, Prop, Vue } from 'vue-property-decorator';
import BackButton from '@/components/ui/BackButton.vue';

@Component({
    components: {
        BackButton
    }
})
export default class SubscribeSuccess extends Vue {

    get heroAttrs() {
        return {
            class: `dn-subscribe-success__hero`,
            style: `background-image: url(https://storage.googleapis.com/drumnow-apps_public/images/subscribe-landing.jpg)`
        }
    }
}
